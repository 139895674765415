<template>
  <div class="editor-device-gullies">
    <fieldset style="margin-top:40px;">
      <legend>Gullies</legend>

      <div class="row mb20">
        <div class="col-md-4">
          <label for="gully-dmax">
            Height from empty gully to sensor (DMax) (m):
          </label>
          <input
            id="gully-dmax"
            class="form-control"
            type="number"
            step="0.01"
            v-model.number="device.water.dmax"
          />
          <span class="text-danger" v-text="errors['water.dmax']"></span> &nbsp;
        </div>
        <div class="col-md-4">
          <label for="gully-crit">Depth at 100% Capacity (m):</label>
          <input
            id="gully-crit"
            class="form-control"
            type="number"
            step="0.01"
            v-model.number="device.water['level-critical']"
          />
          <span
            class="text-danger"
            v-text="errors['water.level-critical']"
          ></span>
          &nbsp;
        </div>
        <div class="col-md-4">
          <label for="gully-ct">Change Tolerance (m):</label>
          <input
            id="gully-ct"
            class="form-control"
            type="number"
            step="0.1"
            v-model.number="device.water['change-tolerance']"
          />
          <span
            class="text-danger"
            v-text="errors['water.change-tolerance']"
          ></span>
          &nbsp;
        </div>
      </div>
      <div class="row mb20">
        <div>&nbsp;</div>
      </div>
      <div class="row mb20">
        <div class="col-md-4">
          <label for="gully-alarm-c">Alarm: Critical Depth (m</label>
          <input
            id="gully-alarm-c"
            class="form-control"
            type="number"
            step="0.01"
            v-model.number="device.alarms.criteria.gullies.critical"
          />
          <span
            class="text-danger"
            v-text="errors['alarms.criteria.gullies.critical']"
          ></span>
          &nbsp;
        </div>
        <div class="col-md-4">
          <label for="gully-alarm-w">Alarm: Warning Depth (m)</label>
          <input
            id="gully-alarm-w"
            class="form-control"
            type="number"
            step="0.01"
            v-model.number="device.alarms.criteria.gullies.warning"
          />
          <span
            class="text-danger"
            v-text="errors['alarms.criteria.gullies.warning']"
          ></span>
          &nbsp;
        </div>
        <div class="col-md-4"></div>
      </div>

      <div class="row mb20">
        <!-- rain -->
        <div class="col-md-6">
          <label for="gully-rain">Local Rainfall:</label>
          <span class="input-group">
            <span class="input-group-btn">
              <button class="btn btn-default" onclick="">
                <span><i class="fa fa-share"></i></span>
              </button>
            </span>
            <small>
              <input
                id="gully-rain"
                class="form-control"
                v-bind:value="rainlocal_name"
                readonly
                type="text"
              />
            </small>
            <span class="input-group-btn">
              <button
                class="btn btn-default"
                v-on:click="showHideNeighbourPicker('rain_local')"
                title="Choose Rainfall Device"
              >
                <i class="fa fa-link"></i>
              </button>
            </span>
          </span>
          <span
            class="text-danger"
            v-text="errors['gullies.rain_local']"
          ></span>
          &nbsp;
        </div>
      </div>

      <!-- modal -->
      <sc-modal
        v-if="showNeighbourPicker"
        @close="showHideNeighbourPicker(null)"
      >
        <h3 slot="title">Choose Neighbour</h3>
        <div slot="body">
          <ul v-show="showNeighbourPicker == 'rain_local'">
            <li>
              <i> <a v-on:click="selectNeighbour(null)">(none)</a> </i>
            </li>
            <li v-for="fd in rain_devices" v-bind:key="fd._id">
              <a v-on:click="selectNeighbour(fd)">{{ fd.nickname }}</a>
            </li>
          </ul>
        </div>
      </sc-modal>

      
    </fieldset>
  </div>
</template>

<script>
export default {
  props: ["device", "errors"],
  data: function() {
    return {
      showNeighbourPicker: null,
      neighbours: {
        rain_local: null,
      },
      gullies_devices: null,
      rain_devices: null,
    };
  },
  computed: {
    rainlocal_name: function() {
      var vm = this;
      return (
        _.get(vm.neighbours, "rain_local.nickname") ||
        _.get(vm.device, "gullies.rain_local")
      );
    },
  },
  methods: {
    showHideNeighbourPicker: function(neighbour) {
      var vm = this;
      vm.showNeighbourPicker = neighbour;
      if (vm.gullies_devices == null || vm.gullies_devices.length == 0) {
        // access the urls object, set in page with Vue.prototype.urls
        var url = vm.urls.devices + "?scheme=gullies";
        axios.get(url).then(function(resp) {
          Vue.set(vm, "gullies_devices", resp.data);
        });
      }
      if (vm.rain_devices == null || vm.rain_devices.length == 0) {
        // access the urls object, set in page with Vue.prototype.urls
        var url = vm.urls.devices + "?scheme=rain";
        axios.get(url).then(function(resp) {
          Vue.set(vm, "rain_devices", resp.data);
        });
      }
    },
    selectNeighbour: function(device) {
      var vm = this;
      if (device) {
        vm.device.gullies[vm.showNeighbourPicker] = device._id;
      } else {
        vm.device.gullies[vm.showNeighbourPicker] = null;
      }
      vm.neighbours[vm.showNeighbourPicker] = device;
      vm.showNeighbourPicker = null;
    },
  },
  created: function() {
    var vm = this;
    if (vm.device.gullies) {
      _.each(["rain_local"], function(n) {
        // access the urls object, set in page with Vue.prototype.urls
        var url = vm.urls.device;
        if (vm.device.gullies[n]) {
          var deviceUrl = url.replace("PLACEHOLDER:oid", vm.device.gullies[n]);
          axios.get(deviceUrl).then(function(resp) {
            vm.neighbours[n] = resp.data;
          });
        }
      });
    }
  },
};
</script>

<style></style>
